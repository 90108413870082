.calendar-full .rbc-day-slot .rbc-event {
  border-color: white;
  overflow: visible;
}

.calendar-full .rbc-day-slot .rbc-event .rbc-event-content {
  overflow: hidden;
}

.calendar-full .rbc-header {
  font-weight: normal;
  border: 0;
  color: #616161; /* grey-700 */
  padding: 0;
}

.calendar-full .rbc-header p {
  font-weight: 400;
}

.calendar-full .rbc-timeslot-group {
  border-bottom: 1px solid #e7e8ea;
  min-height: 55px;
}

.calendar-full .rbc-time-header {
  border-bottom: 1px solid #e7e8ea;
  border-right: none;
  margin-right: 0 !important; /* Overrides element inner style */
  padding-right: 22px; /* Fixes "centralized" header  */
}

.calendar-full .rbc-time-header-gutter {
  border-bottom: none !important;
}

.calendar-full .rbc-time-content {
  padding-right: 16px;
  border-top: 0;
}

.calendar-full .rbc-time-content > * + * > * {
  border-left: 1px solid #e7e8ea;
}

.calendar-full .rbc-slot-selection {
  color: #0d1730;
  background-color: #eaf2ff;
  border-radius: 5px;
}

.calendar-full .rbc-current-time-indicator {
  display: block !important;
  height: 2px;
  background-color: #fd3001;
  z-index: 0;
}

.calendar-full .rbc-allday-cell {
  display: none;
}

.calendar-full .rbc-header + .calendar-full .rbc-header {
  border: 0;
}

.calendar-full .rbc-time-slot {
  color: #666b7a;
  min-width: 55px;
  text-align: right;
  font-size: 12px;
  margin-top: -5px !important;
  text-align: center;
}

.calendar-full .rbc-time-gutter .rbc-timeslot-group {
  border: 0;
  transform: translateY(-7px);
}

.calendar-full .rbc-time-gutter .rbc-timeslot-group:first-child {
  /* transform: translateY(0px); */
  visibility: hidden;
}

.calendar-full .rbc-time-view {
  border: 0;
}

.calendar-full .rbc-time-header-content {
  border: 0;
}

.calendar-full .rbc-today {
  background-color: inherit;
}

.calendar-full .rbc-day-slot .rbc-time-slot {
  border: 0;
}

.calendar-full .rbc-time-header-cell-single-day {
  display: block;
}

.event-blue.striped-event {
  background: #0081ff
    repeating-linear-gradient(
      45deg,
      rgba(11, 38, 82, 0),
      rgba(11, 38, 82, 0) 8px,
      rgba(11, 38, 82, 0.4) 8px,
      rgba(11, 38, 82, 0.4) 10px
    );
}

.event-grey.striped-event {
  color: #717c80;
  background: #f3f4f6
    repeating-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 8px,
      rgba(0, 0, 0, 0.06) 8px,
      rgba(0, 0, 0, 0.06) 10px
    );
}

.event-blue {
  color: #fff;
  background-color: #0081ff;
}

#client-portal-root .event-grey {
  color: #717c80 !important;
  background-color: #f3f4f6 !important;
}

.rbc-day-slot * .past-timeslot {
  background-color: #e7e8ea;
}

.calendar-full:not(.provide-availability) .rbc-event:hover {
  z-index: 1;
}

.calendar-full .rbc-addons-dnd-resize-ns-anchor:first-child {
  pointer-events: none;
  display: none;
}

.calendar-full .rbc-addons-dnd-resize-ns-anchor:last-child {
  cursor: ns-resize;
  width: calc(100% + 16px) !important;
  height: 20px;
  margin: -8px;
}
