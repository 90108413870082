.cited-markdown ul li::before,
.cited-markdown ol li::before {
  content: none;
}

.cited-markdown > p {
  margin: 0;
}

.cited-markdown > p:not(:last-child) {
  margin-bottom: 8px;
}

.cited-markdown ul,
.cited-markdown ol {
  margin: 0;
  padding-left: 20px;
  list-style-type: auto;
}

.cited-markdown ul:not(:last-child),
.cited-markdown ol:not(:last-child) {
  margin-bottom: 8px;
}

.cited-markdown ul li > p,
.cited-markdown ol li > p {
  margin: 0;
}
