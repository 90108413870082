@import "~@alphasights/alphadesign-tokens/dist/css/portal/tokens.css";
@import "~@alphasights/alphadesign-tokens/dist/css/portal/tokens-classes.css";
@import "~@alphasights/alphaui/dist/alphaui-client-portal.css";

html {
  font-size: initial;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none;
}

body:has(.ads-body-end [role="tooltip"]) {
  overflow-x: hidden !important;
}

@font-face {
  font-family: "Poynter";
  src: url("fonts/PoynterOSDisp-Semibold.eot?#iefix") format("embedded-opentype"),
    url("fonts/PoynterOSDisp-Semibold.otf") format("opentype"), url("fonts/PoynterOSDisp-Semibold.woff") format("woff"),
    url("fonts/PoynterOSDisp-Semibold.ttf") format("truetype"),
    url("fonts/PoynterOSDisp-Semibold.svg#PoynterOSDisp-Semibold") format("svg");
  font-weight: normal;
  font-style: normal;
}

.aui-font-poynter {
  font-family: "Poynter";
}

.aui-text-mini {
  font-size: 0.5rem;
}

.AdvisorFlyOut-RelevantStatement li {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}

.AdvisorCard-RelevantStatement li {
  padding-left: 1.5rem;
}

.aui-animate-spin {
  animation: spin 1s linear infinite;
}

.aui-text-scheduled-purple {
  color: #ae3bc9;
}

.aui-bg-scheduled-purple {
  background-color: #ae3bc9;
}

.aui-border-scheduled-purple {
  border-color: #ae3bc9;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

em.highlighted-text {
  font-style: normal;
  background-color: #fcffc8;
  padding: 0.2em 0.1em;
}

input:focus::-webkit-input-placeholder {
  opacity: 0.5;
}

::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 300;
}

.aui-placeholder-grey-3::-webkit-input-placeholder {
  color: #a2acb0;
}

.rbc-current-time-indicator,
.rbc-noallday .rbc-allday-cell {
  display: none;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 20 !important;
}

.rbc-event-label {
  white-space: normal !important;
}

/*
Some !important overrides here are due to ADS being biased
towards the delivery calendar version.

Also, we may need to add an id in the selector to increase
specificity and ensure that the overrides are applied.
*/

#client-portal-root .rbc-event.rbc-selected,
#client-portal-root .rbc-event {
  border: 1px solid !important;
  padding: 2px 5px !important;
}

.rbc-row {
  padding: 0 !important;
  border: 0 !important;
}

#client-portal-root .rbc-header {
  height: unset;
}

#client-portal-root .event-blue {
  color: #fff !important;
  background-color: #0081ff !important;
}

#client-portal-root .event-light-blue {
  color: #0d1730 !important;
  background-color: #eaf2ff !important;
}

@media (min-width: 1024px) {
  .lg\:aui-max-w-screen-910 {
    max-width: 910px;
  }

  .lg\:aui-max-w-screen-1440 {
    max-width: 1440px;
  }
}

.aui-icon.aui-leading-normal {
  line-height: 1.5;
}

.aui-bg-highlight {
  background-color: rgb(251, 235, 229);
}

.hover\:aui-bg-highlight:hover {
  background-color: rgb(251, 235, 229);
}
.aui-border-highlight {
  border-color: rgb(251, 235, 229);
}

.aui-button-gradient {
  background-image: linear-gradient(45deg, #fc8300, #fd3001 69%, #e8236a);
  border: 0;
  color: white;
}

.aui-button-gradient:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

.aui-button-primary:hover,
.aui-button-primary {
  background-color: #0d1730;
  border: #0d1730;
}

.aui-button-primary:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

@media only screen and (min-width: 769px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  :hover::-webkit-scrollbar-thumb {
    background: rgba(162, 172, 176, 0.5);
  }
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.option:hover {
  background-color: var(--color-background-surface-page-hover);
}

::placeholder {
  color: var(--color-text-assistive);
}

.textBox:focus {
  outline-style: solid;
}

.cky-consent-bar {
  display: none;
}
