.Advisor-RelevantStatement ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Advisor-RelevantStatement ol {
  margin-left: 0;
  padding: 0;
  list-style: auto;
}

.Advisor-RelevantStatement ol li {
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-left: 0.1rem;
  margin-left: 1.25rem;
}

.Advisor-RelevantStatement ul li {
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-left: 0.875rem;
  margin-left: 0.5rem;
}

.Advisor-RelevantStatement ul li:before {
  content: "\2022";
  position: absolute;
  left: 0;
}

.Advisor-RelevantStatement li:last-child {
  margin-bottom: 0.25rem;
}
