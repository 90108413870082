.arrow-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-left: 10px solid #eaf2ff;
}

.aui-group:hover .arrow-right {
  border-left-color: #4fc3f7;
}
